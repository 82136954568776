<template>
    <div id="your-component">
        <!-- Using Component -->
        <calendar
            :eventCategories="eventCategories"
            :events="eventsList"
            :firstDayOfWeek="2"
            :offDays=[]
            :initialDate="active_until"
            ref="calendar"
        />
    </div>
</template>

<script>
// Importing Component and style
import { Calendar } from 'vue-sweet-calendar'
import 'vue-sweet-calendar/dist/SweetCalendar.css'

export default {
    name: 'MyMembershipCalendar',
    props: [
        'activated_at',
        'active_until',
        'expired_at',
        'consumption_dates',
        'is_expired',
    ],
    data() {
        return {
            eventCategories: [
                {
                    id: 1,
                    title: 'Срок действия',
                    textColor: 'black',
                    backgroundColor: '#dee2e6'
                },
                {
                    id: 2,
                    title: 'Посещения',
                    textColor: 'white',
                    backgroundColor: 'blue'
                }
            ],
        }
    },
    computed: {
        eventsList() {
            let events = [];
            if (this.is_expired) {
                events.push(this.makeEvent(this.activated_at, this.expired_at, 1));
            } else {
                events.push(this.makeEvent(this.activated_at, this.active_until, 1));
            }
            for (let date of JSON.parse(this.consumption_dates)) {
                events.push(this.makeEvent(date, date, 2));
            }

            return events;
        },
    },
    methods: {
        goToday() {
            this.$refs.calendar.goToday()
        },
        makeEvent(start, end, category_id) {
            return {
                title: 'Event',
                start: start,
                end: end,
                repeat: 'never',
                categoryId: category_id
            }
        }
    },
    components: {
        Calendar // Registering Component
    }
}
</script>

<style scoped>

</style>



